import React from 'react';
import styled from 'styled-components';
import Button from '@components/buttons';
import { Box, Flex } from '@components/layout/Grid';
import Icon from '@components/Icon';
import {
  Disclaimer,
  HeroBackground,
  Paragraph,
  SubscriptionTag,
  SubTitle,
  TextWrapper,
  TextWrapperInner,
  Title,
  VideoWrapper,
  ActionsWrapper,
  CategoryTitle,
} from './components';

type ActionType = {
  name: string | JSX.Element;
  theme?: string;
  href?: string;
  onClick?(): void;
};

export const Thumbnail = styled.img``;

export type titleAs = 'h1' | 'h2' | 'h3';

export type Props = {
  className?: string;
  whiteBackground?: boolean;
  backgroundImage?: string;
  reverseColumns?: boolean;
  title?: React.ReactNode;
  titleAs?: titleAs;
  subtitle?: React.ReactNode;
  noSubtitleWrapper?: boolean;
  description?: React.ReactNode;
  disclaimer?: React.ReactNode;
  actions?: Array<ActionType | JSX.Element | React.ReactNode>;
  image?: string;
  imageSrcSet?: string;
  imageSizes?: string;
  video?: React.ReactNode;
  transparentColor?: boolean;
  darkTheme?: boolean;
  heroSection?: boolean;
  category?: React.ReactNode;
  checkTags?: Array<React.ReactNode>;
  px?: any;
  py?: any;
};

const Hero = (props: Props) => {
  return (
    <Flex
      as={HeroBackground}
      whiteBackground={props.whiteBackground}
      backgroundImage={props.backgroundImage}
      backdrop={props.heroSection}
      flexDirection={props.reverseColumns ? 'row-reverse' : 'row'}
      alignItems="center"
      flexWrap="wrap"
      className={props.className}
      px={props.px}
      py={props.py}
    >
      {(props.video || props.image || props.category) && (
        <Box as={VideoWrapper} width={[1, 1, 1 / 2]}>
          {props.image ? (
            <Thumbnail
              className="w-full object-contain rounded-[8px]"
              src={props.image}
              srcSet={props.imageSrcSet}
              sizes={props.imageSizes}
              loading="lazy"
              alt=""
            />
          ) : null}
          {props.video ? props.video : null}
        </Box>
      )}
      {(props.hasOwnProperty('title') ||
        props.hasOwnProperty('description')) && (
        <Box
          as={TextWrapper}
          width={[1, 1, 1 / 2]}
          pr={props.reverseColumns ? ['10px', '10px', '40px'] : null}
          pl={props.reverseColumns ? null : [0, 0, '40px']}
          pt={[20, 20, 0]}
          transparentColor={props.transparentColor}
          alignItems="center"
        >
          <TextWrapperInner className="h-full flex flex-wrap flex-col justify-center">
            {props.category && <CategoryTitle>{props.category}</CategoryTitle>}
            {props.title && (
              <Title
                as={props.titleAs ?? 'h2'}
                dark={!!props.transparentColor || props.darkTheme}
              >
                {props.title}
              </Title>
            )}
            {props.checkTags && (
              <Flex flexWrap="nowrap" alignItems="flex-start" mb="20px">
                {props.checkTags.map((tag, index) => (
                  <Box
                    as={SubscriptionTag}
                    mr={20}
                    alignItems="center"
                    justifyContent="center"
                    key={index}
                    dark={!!props.transparentColor}
                  >
                    <Icon
                      name="ygb-icon-checkmark"
                      fontSize="14px"
                      style={{ marginRight: '10px' }}
                    />
                    {tag}
                  </Box>
                ))}
              </Flex>
            )}
            {/* if subtitle is provided */}
            {props.subtitle ? (
              props.noSubtitleWrapper ? (
                props.subtitle
              ) : (
                <SubTitle dark={!!props.transparentColor || props.darkTheme}>
                  {props.subtitle}
                </SubTitle>
              )
            ) : null}
            {props.description && typeof props.description === 'string' ? (
              <Paragraph
                className="Hero--Description"
                dark={!!props.transparentColor || props.darkTheme}
              >
                {props.description}
              </Paragraph>
            ) : (
              props.description
            )}
            {props.actions && (
              <Flex
                as={ActionsWrapper}
                flexWrap="nowrap"
                alignItems="center"
                justifyContent="flex-start"
              >
                {props.actions.map((action, index) => {
                  if (React.isValidElement(action)) {
                    return React.cloneElement(action, { key: index });
                  } else {
                    const button = action as ActionType;
                    return (
                      <Button
                        key={index}
                        appearance={button.theme ? button.theme : null}
                        onClick={button.onClick}
                        href={button.href}
                      >
                        {button.name}
                      </Button>
                    );
                  }
                })}
              </Flex>
            )}
            {props.disclaimer ? (
              <Disclaimer dark={!!props.transparentColor || props.darkTheme}>
                {props.disclaimer}
              </Disclaimer>
            ) : null}
          </TextWrapperInner>
        </Box>
      )}
    </Flex>
  );
};
export default Hero;
