import React from 'react';
import styled, {
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';
import { Box, Flex } from '@components/layout/Grid';
import { SubTitle } from '@components/texts';
import { Title } from '@ecosystems/landing_pages/hero/components';

const Header = styled.h2`
  font-size: 22px;
  color: ${(props) => props.theme.colors.neutrals.black};
  font-weight: 500;
  line-height: 1.4;
  margin: 0;
  padding: 0;
`;

type Props = {
  className?: string;
  title: React.ReactNode;
  titleAs?: 'h1' | 'h2' | 'h3';
  subTitle?: React.ReactNode;
  subTitleStyle?: FlattenInterpolation<ThemeProps<DefaultTheme>> | string;
  secondSubtitle?: React.ReactNode;
  button?: React.ReactNode;
};

const SectionHeader = (props: Props) => (
  <Flex
    className={props.className}
    justifyContent="space-between"
    alignItems="center"
    mb={15}
  >
    <Box>
      {props?.titleAs ? (
        <Title as={props?.titleAs} className="SectionHeader !mb-2">
          {props.title}
        </Title>
      ) : (
        <Header className="SectionHeader">{props.title}</Header>
      )}
      {props.subTitle && (
        <SubTitle
          {...(props.subTitleStyle ? { customStyle: props.subTitleStyle } : {})}
        >
          {props.subTitle}
        </SubTitle>
      )}
      {props.secondSubtitle && <SubTitle>{props.secondSubtitle}</SubTitle>}
    </Box>
    {props.button ? (
      <Box flexShrink={0} ml={[40, 40, 0]}>
        {props.button}
      </Box>
    ) : null}
  </Flex>
);

export default SectionHeader;
